@import "../../../mixins.scss";

.HoliCateHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  text-transform: capitalize;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    h5 {
      margin-bottom: 0 !important;
    }
  }

  p {
    margin-bottom: 0 !important;
    font-weight: 600;
  } 
  img {
    width: 30px;
    // margin-right: 8px;
  }
}

.paddHotCategory {
  padding: 21px;
  border-radius: 10px;
  margin-bottom: 40px;
  overflow-x: hidden;

  @include md {
    padding: 21px 43px !important;
  }
}



// .swiper-button-prev,
// .swiper-button-next {
//   display: none !important; /* Hide the default navigation buttons */
// }

.custom-prev, .custom-next {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
  border: none !important;
  border-radius: 50% !important;
  padding: 10px !important;
  z-index: 10 !important;
  cursor: pointer !important;
  display: block !important;
}

.custom-prev {
  left: 3px !important;
}

.custom-next {
  right: 3px !important;
}


