.plane-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.plane {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.plane-body {
  /* display: grid; */
  /* grid-template-columns: repeat(7, 1fr); */
  gap: 5px;
  background-color: #fff;
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  top: -2px;
  z-index: 1;
  display: flex;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  background-color: #fff;
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  z-index: 1;
  flex-direction: column;
  /* box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.5),
      5px 0 10px -5px rgba(0, 0, 0, 0.5); */
  gap: 8px;
}

.plane-head,
.plane-tail {
  width: 120px;
  height: 60px;
  background-color: #fff;
  border-radius: 60px 60px 0 0;
  /* margin-bottom: -30px; */
  z-index: 1;
}

.plane-head {
  /* border-radius: 60px 60px 0 0; */
  /* margin-bottom: -30px; */
  border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% !important;
  width: 100%;
  height: 200px;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.5),
    /* Top shadow */ -5px 0 10px -5px rgba(0, 0, 0, 0.5),
    /* Left shadow */ 5px 0 10px -5px rgba(0, 0, 0, 0.5); /* Right shadow */
  position: relative;
  transform: rotate(180deg);
}
.plane-headd {
  width: 100.2%;
  transform: rotate(180deg);
}
.plane-headd img {
  width: fit-content;
  fill: #ffff;
}

.flightLayout-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: start;
}
.flightLayout-container-inner {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.plane-head-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% !important;
  width: 71%;
  height: 70%;
  z-index: 300;
  background-color: #6c757d;
}
.plane-head-div2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% !important;
  width: 15px;
  height: 70%;
  z-index: 300;
  background-color: #fff;
}
.plane-head-div1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  /* border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% !important; */
  width: 71%;
  height: 10px;
  z-index: 300;
  background-color: #fff;
}
.palne-tail-container {
  position: relative;
  background: transparent;
  z-index: 10;
  height: 335px;
  height: 200px;
  height: 235px;
  width: 100%;
}
.plane-tail {
  /* border-radius: 0 0 60px 60px;
        margin-top: -30px; */
  border-radius: 50% 50% 50% 50% / 0% 0% 100% 100% !important;
  width: 100%;
  height: 200px;
  height: 233px;
  position: absolute;
  box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.5),
    /* Left shadow */ 5px 0 10px -5px rgba(0, 0, 0, 0.5),
    /* Right shadow */ 0 5px 10px -5px rgba(0, 0, 0, 0.5); /* Bottom shadow */
}
.palin-tail-wing {
  width: 378px;
  height: 111px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  /* transform: rotate(26deg); */
  z-index: 0;
}
.palin-tail-wing-top {
  position: absolute;
  background: red;
  width: 378px;
  height: 110px;
  left: -49px;
  border-radius: 100%;
  top: 24px;
}
.palin-tail-wing-bottom {
  position: absolute;
  position: absolute;
  background: #f4f3f3;
  /* background: #fff; */
  width: 378px;
  height: 110px;
  left: -49px;
  border-radius: 100%;
  top: 128px;
}
.palin-tail-wing-cylender {
  background: gray;
  /* background: #fff; */
  width: 48px;
  height: 88%;
  border-radius: 20px;
  /* top: 128px; */
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .palin-tail-wing {
        width: 207px;
        height: 111px;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: red;
        transform: rotate(26deg);
        z-index: 0;
    } */

.plane-wing {
  width: 200px;
  height: 50px;
  background-color: gray;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.left-wing {
  left: -210px;
}

.right-wing {
  right: -210px;
}

.plane-row {
  /* display: contents; */
  display: flex;
  gap: 5px;
}

.seat {
  /* width: 30px;
      height: 30px; */
  width: 22.5px;
  height: 22.5px;
  /* color: transparent !important; */
  color: transparent !important;

  /* background-color: lightgray; */
  /* background-color: rgb(186, 218, 255); */
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  /* border: 1px solid black; */
  font-size: 12px;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  cursor: pointer;
}
.seat-default {
  background-color: rgb(186, 218, 255);
}
.seat-price-high {
  background-color: #c9baff !important;
}
.seat-price-low {
  background-color: #badaff !important;
}
seat :not(.seat-occupied) {
  cursor: pointer;
}
.restricted-recline {
  border-bottom: 4px solid rgb(0, 0, 0);
}
.legroom {
  border-top: 4px solid rgb(81, 37, 110);
}
.exit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
}
.exit1 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  /* width: 30px; */
  height: 30px;
  color: red;
  border-right: 2px solid red;
  padding: 3px;
}
.exit2 {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  /* width: 30px; */
  height: 30px;
  color: red;
  border-left: 2px solid red;
  padding: 3px;
}
.exit-row {
  /* background-color: rgb(201, 186, 255) !important; */
  /* color: rgb(201, 186, 255) !important; */
  /* border: 1px solid blue; */
  position: relative;
}
.exit-row::before {
  width: 11px;
  height: 7px;
  background: #a30014;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  border-bottom-left-radius: 15px;
}
.extra-lag-room {
  border-top: 4px solid red !important;
}

.seat-occupied {
  background-color: #d2d1d1 !important;
  /* color: #ededed !important; */
  /* color: black !important; */
  /* color: transparent !important; */
  /* color: transparent!important; */
  position: relative;
  /* content: "X"; */
  cursor: default !important;
}
/* .seat-occupied::before {

    position: absolute;
    z-index: 1;
    content: "X";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    cursor: default !important;
  } */
.seat-occupied-def {
  background-color: #d2d1d1;
  /* color: #ededed !important; */
  /* color: black !important; */
  color: transparent;
}
.lavatory {
  background: #e0e0e0;
  height: 30px;
  /* padding: 2px 12px; */
  border-radius: 4px;
  margin-top: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.free-seat {
  background-color: rgb(80, 227, 194) !important;
}
.free-seat-def {
  background-color: rgb(80, 227, 194);
}

.aisle {
  background-color: transparent !important;
  border: none !important;
  color: transparent !important;
  position: relative;
  cursor: default !important;
}
.aisle::before {
  width: 11px;
  height: 7px;
  background: transparent !important;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  border-bottom-left-radius: 15px;
}

.business {
  background-color: gold;
}

.premium {
  background-color: silver;
}
.seatSelected {
  background-color: #4f46e5 !important;
}

.FLightSeatMapBox {
  height: 650px;
  overflow: hidden;
  overflow-y: scroll;
  /* background-color: #d90429; */
  background-color: #eef2ff;
}

:where(.css-dev-only-do-not-override-3rel02).ant-carousel .slick-dots-bottom {
  bottom: -23px !important;
}
:where(.css-3rel02).ant-carousel .slick-dots-bottom {
  bottom: -23px !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-carousel
  .slick-dots
  li.slick-active {
  width: 72px !important;
  background: #d90429 !important;
}
:where(.css-3rel02).ant-carousel .slick-dots li.slick-active {
  width: 72px !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-carousel .slick-dots li {
  background: #d90429 !important;
  height: 7px !important;
}
:where(.css-3rel02).ant-carousel .slick-dots li {
  background: #d90429 !important;
  height: 7px !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: #d90429 !important;
  opacity: 1;
}

:where(.css-3rel02).ant-carousel .slick-dots li.slick-active button {
  background: #d90429 !important;
  opacity: 1;
}

.palne-tail-container::before {
  position: absolute;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  background-color: orange;
}
.tail-img {
  width: 100%;
}
.tooltip-air {
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  top: -56px;
  color: #668 !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  display: flex;
  min-width: 150px;
  max-width: 200px;
  flex-wrap: wrap;
  white-space: nowrap;
  line-height: 22px !important;
  /* gap: 8px; */
}
.tooltip-title-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0px 5px;
  background-color: #7777771f;
}
.tooltip-title-box-3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: #d904290f;
  padding: 0px 5px;
}

.tooltip-title-box p {
  line-height: 22px !important;
}

.tooltip-title-box-3 p {
  line-height: 22px !important;
}

.tooltip-air::after {
  content: "";
  position: absolute;
  width: 35px;
  height: 12px;
  background-color: #fff !important;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 0%);
  clip-path: polygon(100% 0, 0 0, 53% 100%);
}
.seat-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: aliceblue; */
  position: sticky;
  top: 0;
  /* gap: 5px; */
  z-index: 200;
  /* padding: 5px 10px; */
  flex-direction: column;
}
.seat-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: aliceblue;
  width: 100%;
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 200; */
  padding: 5px 10px;
}
.seat-navbar-left-1 {
  display: flex;
  align-items: center;

  gap: 5px;
  font-size: 16px;
  font-weight: 600;
}
.seat-navbar-left-2 p {
  color: #4a4a4a;
}
.navbar-right-price {
  font-weight: 700;
  text-align: center;
}
.plane-type {
  /* position: absolute; */
  /* top: 100px; */
  /* left: 20px; */
  /* z-index: 999; */
  /* background: #d90429; */
  background: #5f5f5f;
  padding: 10px;
  /* border-radius: 7px; */
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.plane-type-container {
  display: flex;
  /* align-items: center; */
  gap: 7px;
  width: 100%;
  /* width: 100px;
    background-color: black; */
  overflow: scroll;
  /* color:#ffff */
}
.plane-type-item-title {
  white-space: nowrap;
  font-size: 12px;
}
.plane-type-item {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #ffff;
}

/* HTML: <div class="loader"></div> */
.loader-Seat-Map {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #f9eded;
  border-right-color: #d90429;
  animation: l2 1s infinite linear;
}
.slick-next:before,
.slick-prev:before {
  color: #d90429;
  font-size: 25px;
}
:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-next {
  opacity: 8.5;
}
:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-prev::after,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-next::after {
  background-color: transparent !important;
  color: transparent !important;
}
.plane-type-container::-webkit-scrollbar {
  width: 20px !important;
}

/* Track */
.plane-type-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px;
}

/* Handle */
.plane-type-container::-webkit-scrollbar-thumb {
  background: red !important;
  border-radius: 10px !important;
}
.plane-taill {
  position: relative;
}
.plane-taill-w {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 318px;
  z-index: -1;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
